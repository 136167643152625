import React from "react";
import theme from "./chakra-ui/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "./Router";

function App() {
  return (
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </React.StrictMode>
  );
}

export default App;
