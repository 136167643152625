import { extendTheme } from "@chakra-ui/react";
import { colors, fonts, fontWeights, styles } from "./theme";

const theme = extendTheme({
  fonts,
  styles,
  colors,
  fontWeights,
});

export default theme;
