import { address, address2, mobile, phone } from "../../global";
import { Box, Flex, Stack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Stack
      w="100%"
      bg="white"
      py="2rem"
      align="start"
      px={["4rem", "4rem", "4rem", "8rem", "8rem"]}
      color="#474746"
    >
      <Flex gap="4rem" align="start" mb="0.8rem" w="100%">
        <Stack>
          <Box fontWeight="bold" fontSize="md" mb="0.2rem">
            ADDRESS
          </Box>

          <Box fontSize="md">{address}</Box>
          <Box fontSize="md" mb="0.8rem">
            {address2}
          </Box>
          <Flex gap="0.4rem" align="center" fontSize="md" color="#474746">
            <Box fontWeight="bold">PHONE</Box>
          </Flex>
          <Box>P: {phone}</Box>
          <Box>M: {mobile}</Box>
        </Stack>
        <Stack>
          <Box fontWeight="bold" fontSize="md" mb="0.2rem">
            OPENING HOURS
          </Box>

          <Box fontSize="md">Weekdays: 9:00AM - 1:00PM</Box>
          <Box fontSize="md">Saturday: 9:00AM - 1:00PM</Box>
          <Box fontSize="md" fontWeight="bold">
            Appointment Only
          </Box>
        </Stack>
      </Flex>

      <Box m="auto" fontSize="sm">
        COPYRIGHT © 2024 Ramsgate Chinese Medicine Clinic
      </Box>
    </Stack>
  );
};

export default Footer;
