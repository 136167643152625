import { Box, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import Chow from "./images/christina.jpeg";
import { CSSTransition } from "react-transition-group";
import "../testimonials/FadeIn.css"; // Add this CSS file for styling
import { useEffect, useState } from "react";

const Biography = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set isVisible to true after a delay (adjust the delay as needed)
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <CSSTransition
      in={isVisible}
      timeout={300} // Duration of the transition in milliseconds
      classNames="fade"
      unmountOnExit
    >
      <Flex
        align={["center", "center", "center", "start", "start"]}
        justify="space-between"
        color="#2a523e"
        direction={[
          "column-reverse",
          "column-reverse",
          "column-reverse",
          "row",
          "row",
        ]}
        px="1rem"
        gap="2rem"
      >
        <Stack py="4rem">
          <Heading>Dr Christina Chow</Heading>

          <Box>
            Dr Christina Chow is a Traditional Chinese Medicine Practitioner
            with over 35 years of experience who specialises in infertility
            solutions. Through the art of acupuncture, Christina applies a
            wealth of experience to stimulate vital energy pathways, promoting
            balance and addressing factors associated with infertility.
            Experience the transformative power of acupuncture as Dr Christina
            guides you on a personalised journey toward fertility and overall
            well-being.
          </Box>
        </Stack>
        <Image
          borderRadius="full"
          boxSize="20rem"
          src={Chow}
          alt="Dan Abramov"
        />
      </Flex>
    </CSSTransition>
  );
};

export default Biography;
