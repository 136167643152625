export const services = [
  "Herbal Medicine",
  "Male / Female Infertility Treatment",
  "Stress Relief",
  "Sciatica",
  "Sports Injuries",
  "Skin Diseases",
  "Sinus",
  "Migraine",
  "Low back & neck/shoulder pain",
  "Menopause",
];
