import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { RiServiceFill, RiContactsFill } from "react-icons/ri";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "../testimonials/FadeIn.css"; // Add this CSS file for styling

const HomeContent = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set isVisible to true after a delay (adjust the delay as needed)
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Stack
      py="4rem"
      px={["4rem", "4rem", "4rem", "8rem", "16rem"]}
      bg="#fefff7"
      gap="2rem"
      minH="100vh"
    >
      <Heading alignSelf="center" mb="2rem">
        Ramsgate Chinese Medicine Clinic
      </Heading>
      <Box mb="4rem">
        Dr Christina Chow is a Traditional Chinese Medicine Practitioner with
        over 35 years of experience who specialises in infertility solutions.
        She offers traditional chinese medicine and acupuncture services to help
        with balanced health and fertility.
      </Box>
      <CSSTransition
        in={isVisible}
        timeout={300} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit
      >
        <Flex
          w="100%"
          justify="space-between"
          gap={["4rem", "4rem", "2rem"]}
          align={["center", "center", "start"]}
          direction={["column", "column", "row"]}
        >
          <Stack w="20rem" align="center">
            <FaPersonBreastfeeding size="6rem" />
            <Heading fontSize="2xl">Services</Heading>
            <Box textAlign="center">
              Browse our services and read about how they can help your health
              and wellbeing.
            </Box>
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/about");
              }}
              color="white"
              bg="#475746"
              _hover={{ background: "#617860" }}
            >
              Find Out More
            </Button>
          </Stack>
          <Stack w="20rem" align="center">
            <RiServiceFill size="6rem" />
            <Heading fontSize="2xl">Testimonials</Heading>
            <Box textAlign="center">
              See what our clients have had to say about our services!
            </Box>
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/testimonials");
              }}
              color="white"
              bg="#475746"
              _hover={{ background: "#617860" }}
            >
              Read Here
            </Button>
          </Stack>
          <Stack w="20rem" align="center">
            <RiContactsFill size="6rem" />
            <Heading fontSize="2xl">Contact</Heading>
            <Box textAlign="center">
              Contact us to make a booking or visit us. Available by appointment
              only.
            </Box>
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/contact");
              }}
              color="white"
              bg="#475746"
              _hover={{ background: "#617860" }}
            >
              Contact Us
            </Button>
          </Stack>
        </Flex>
      </CSSTransition>
    </Stack>
  );
};
export default HomeContent;
