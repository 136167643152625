import { Box } from "@chakra-ui/react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMemo } from "react";
import { useIsMobile } from "../../hooks/useIsMobile/useIsMobile";
import "./map.css";

const Map = () => {
  const isMobile = useIsMobile();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY as string,
  });

  const center = useMemo(() => ({ lat: -33.98522, lng: 151.14369 }), []);
  return (
    <Box>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName={
            isMobile ? "map-container-mobile" : "map-container"
          }
          center={center}
          zoom={15}
        >
          <Marker position={{ lat: -33.98522, lng: 151.14369 }} />
        </GoogleMap>
      )}
    </Box>
  );
};

export default Map;
