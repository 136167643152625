import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./pages/home";
import AboutUsPage from "./pages/about";
import TestimonialsPage from "./pages/testimonials";
import HomeContent from "./pages/home/HomeContent";
import ContactPage from "./pages/contact";

export const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      children: [
        {
          path: "/",
          element: <HomeContent />,
        },
        {
          path: "/about",
          element: <AboutUsPage />,
        },
        {
          path: "/testimonials",
          element: <TestimonialsPage />,
        },
        {
          path: "/contact",
          element: <ContactPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
