import { useBreakpointValue } from "@chakra-ui/react";

export const useIsMobile = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  });
  return isMobile;
};
