import { Heading, Box, Stack, Flex, Button } from "@chakra-ui/react";
import { CSSTransition } from "react-transition-group";
import "../testimonials/FadeIn.css"; // Add this CSS file for styling
import { useEffect, useState } from "react";
import { address, address2, mobile, phone } from "../../global";
import Map from "./Map";

const ContactPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set isVisible to true after a delay (adjust the delay as needed)
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Stack
      py="4rem"
      px={["4rem", "4rem", "4rem", "8rem", "16rem"]}
      bg="bg.cloud"
      gap="2rem"
      minH="100vh"
    >
      <Heading alignSelf="center" mb="2rem">
        Contact Us
      </Heading>
      <CSSTransition
        in={isVisible}
        timeout={300} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit
      >
        <Flex
          w="100%"
          direction={["column", "column", "column", "row", "row"]}
          gap="2rem"
        >
          <Stack
            gap="2rem"
            align="start"
            mb="0.8rem"
            w="100%"
            wrap="wrap"
            direction={["row", "row", "row", "column", "column"]}
          >
            <Stack>
              <Box fontWeight="bold" fontSize="xl" mb="0.2rem">
                ADDRESS
              </Box>

              <Box fontSize="md">{address}</Box>
              <Box fontSize="md" mb="0.8rem">
                {address2}
              </Box>
              <Button
                bg="gray.200"
                _hover={{
                  bg: "gray.300",
                }}
                onClick={() => {
                  window.open(
                    "https://maps.app.goo.gl/zKLjUXRBfAGDHoxF9",
                    "_blank"
                  );
                }}
              >
                Get Directions
              </Button>
            </Stack>
            <Stack>
              <Flex gap="0.4rem" align="center" fontSize="xl" color="#474746">
                <Box fontWeight="bold">PHONE</Box>
              </Flex>
              <Box>P: {phone}</Box>
              <Box>M: {mobile}</Box>
            </Stack>
            <Stack>
              <Box fontWeight="bold" fontSize="xl" mb="0.2rem">
                OPENING HOURS
              </Box>

              <Box fontSize="md">Weekdays: 9:00AM - 1:00PM</Box>
              <Box fontSize="md">Saturday: 9:00AM - 1:00PM</Box>
              <Box fontSize="md" fontWeight="bold">
                Appointment Only
              </Box>
            </Stack>
          </Stack>
          <Map />
        </Flex>
      </CSSTransition>
    </Stack>
  );
};

export default ContactPage;
