import { Box, Flex, Heading, Stack, Image } from "@chakra-ui/react";
import { CSSTransition } from "react-transition-group";
import "./FadeIn.css"; // Add this CSS file for styling
import { testimonials } from "./testimonials";
import SS1 from "./images/1.jpeg";
import SS2 from "./images/2.jpeg";
import SS3 from "./images/3.jpeg";
import SS4 from "./images/4.jpeg";
import SS5 from "./images/5.jpeg";
import SS6 from "./images/6.jpeg";
import SS7 from "./images/7.jpeg";
import SS8 from "./images/8.jpeg";
import SS9 from "./images/9.jpeg";
import SS10 from "./images/10.jpeg";
import SS11 from "./images/11.jpeg";
import SS12 from "./images/12.jpeg";
import SS13 from "./images/13.jpeg";
import SS14 from "./images/14.jpeg";
import { useEffect, useState } from "react";
import { useIsMobile } from "../../hooks/useIsMobile/useIsMobile";

const TestimonialsPage = () => {
  const isMobile = useIsMobile();
  const screenshots = [
    SS1,
    SS2,
    SS3,
    SS4,
    SS5,
    SS6,
    SS7,
    SS8,
    SS9,
    SS10,
    SS11,
    SS12,
    SS13,
    SS14,
  ];

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set isVisible to true after a delay (adjust the delay as needed)
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 200);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Stack
      py="4rem"
      px={["4rem", "4rem", "4rem", "8rem", "16rem"]}
      bg="#ffe6f9"
      gap="2rem"
      minH="100vh"
    >
      <Heading alignSelf="center">Testimonials</Heading>
      <Stack gap="3rem" fontStyle="italic">
        {testimonials.map((t, index) => (
          <CSSTransition
            key={index}
            in={isVisible}
            timeout={300} // Duration of the transition in milliseconds
            classNames="fade"
            unmountOnExit
          >
            <Box key={index}>"{t}"</Box>
          </CSSTransition>
        ))}
      </Stack>
      <CSSTransition
        in={isVisible}
        timeout={300} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit
      >
        <Flex wrap="wrap" gap="1rem">
          {screenshots.map((image) => (
            <Image
              maxW={isMobile ? "8rem" : "11rem"}
              src={image}
              mb="1rem"
              maxH="16rem"
            />
          ))}
        </Flex>
      </CSSTransition>
    </Stack>
  );
};

export default TestimonialsPage;
