import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useIsMobile } from "../../hooks/useIsMobile/useIsMobile";

// assets
import LogoImg from "../../assets/logo.png";

const Header = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex
      w="100%"
      py="1rem"
      px={["2rem", "2rem", "4rem", "8rem"]}
      align="center"
      gap="1rem"
      justify="space-between"
      zIndex="10"
      style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
      position="fixed"
      top="0"
      bg="white"
    >
      <Image
        h="8rem"
        src={LogoImg}
        alt="Logo"
        cursor="pointer"
        onClick={() => {
          window.scroll(0, 0);
          navigate("/");
        }}
      />
      {isMobile ? (
        <>
          <IconButton
            aria-label={"menu-button"}
            icon={<RxHamburgerMenu />}
            onClick={onOpen}
          ></IconButton>
          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />

              <DrawerBody pt="4rem" px="2rem">
                <Stack
                  textAlign={"start"}
                  fontSize="2xl"
                  fontWeight="semibold"
                  color="#475746"
                >
                  <Link
                    to="/"
                    onClick={() => {
                      window.scroll(0, 0);
                      onClose();
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    to="/about"
                    onClick={() => {
                      window.scroll(0, 0);
                      onClose();
                    }}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/testimonials"
                    onClick={() => {
                      window.scroll(0, 0);
                      onClose();
                    }}
                  >
                    Testimonials
                  </Link>
                  <Link
                    to="/contact"
                    onClick={() => {
                      window.scroll(0, 0);
                      onClose();
                    }}
                  >
                    Contact Us
                  </Link>
                </Stack>
              </DrawerBody>

              <DrawerFooter></DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <Flex gap="2rem">
          <Box
            cursor="pointer"
            opacity="0.6"
            _hover={{ opacity: "1" }}
            onClick={() => {
              window.scroll(0, 0);
              navigate("/");
            }}
          >
            Home
          </Box>
          <Box
            cursor="pointer"
            opacity="0.6"
            _hover={{ opacity: "1" }}
            onClick={() => {
              window.scroll(0, 0);
              navigate("/about");
            }}
          >
            About Us
          </Box>
          <Box
            cursor="pointer"
            opacity="0.6"
            _hover={{ opacity: "1" }}
            onClick={() => {
              window.scroll(0, 0);
              navigate("/testimonials");
            }}
          >
            Testimonials
          </Box>
          <Box
            cursor="pointer"
            opacity="0.6"
            _hover={{ opacity: "1" }}
            onClick={() => {
              window.scroll(0, 0);
              navigate("/contact");
            }}
          >
            Contact Us
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
