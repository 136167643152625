import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";
import { CSSTransition } from "react-transition-group";
import "../testimonials/FadeIn.css"; // Add this CSS file for styling
import Biography from "./biography";
import { services } from "./services";
import { useEffect, useState } from "react";

const AboutUsPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set isVisible to true after a delay (adjust the delay as needed)
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <Stack
      py="4rem"
      px={["3rem", "3rem", "3rem", "7rem", "15rem"]}
      bg="bg.cloud"
      gap="2rem"
      minH="100vh"
    >
      <Biography />
      <CSSTransition
        in={isVisible}
        timeout={300} // Duration of the transition in milliseconds
        classNames="fade"
        unmountOnExit
      >
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="xl"
              >
                Services
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box>We offer services in the following categories:</Box>
              <UnorderedList>
                {services.map((service) => (
                  <ListItem>{service}</ListItem>
                ))}
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="xl"
              >
                Infertility Treatment
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Stack gap="1rem">
                <Box>
                  Many Practitioners of Traditional Chinese Medicine (TCM)
                  believe that a variety of energy patterns can interfere with
                  becoming pregnant. Acupuncture can be used in combination with
                  other Western Medicine treatments for infertility when trying
                  to get pregnant. Acupuncture provides a noninvasive, holistic
                  approach to reversing infertility and has a surprising success
                  rate of sixty percent among infertile women; a very high
                  number, considering that many women discover acupuncture late
                  in their search for infertility solutions.
                </Box>
                <Box fontWeight="bold" fontSize="lg">
                  What does infertility treatment include?
                </Box>
                <Box>
                  TCM for infertility includes acupuncture, Chinese herbs, diet,
                  exercise and life style approaches.
                </Box>
                <Box fontWeight="bold" fontSize="lg">
                  What is the procedure of the treatment?
                </Box>
                <Box>
                  The initial phase of treatment involves regulating the
                  menstrual cycle by clearing the congestion in the pelvic
                  cavity, and nourishing the deeper vitality. This phase
                  typically takes 3 to 6 months or more depending on the nature
                  of complaint, trauma, toxin from medication, genetic
                  influences, lifetime habits and illnesses. Within this phase,
                  most women will become fertile and open for conception. You
                  will also benefit from regularity of periods, bright red blood
                  flow without clots, minimal or no menstrual cramping and
                  breast tenderness, better emotional balance, enriching sleep,
                  warm hands and feet, and much more energy. Once conception
                  occurs, second phase treatment increases the probability of a
                  full term pregnancy and decreases the possibility of
                  miscarriage.
                </Box>
                <Box fontWeight="bold" fontSize="lg">
                  How does acupuncture support IVF?
                </Box>
                <Box>
                  Acupuncture can strengthen and balance one’s general health,
                  affect the autonomic nervous system, which is involved in the
                  control of muscles and glands, and could therefore relax the
                  uterus, increase the uterine blood flow and make the lining of
                  the uterus more receptive to receiving an embryo. German
                  researchers have increased success rates by almost 60% in
                  women having IVF (Fertility and Sterility, April 2002 issue).
                  It means more hope, fewer cycles, and a shorter wait for what
                  you most desire: a beautiful, healthy baby. Acupuncture is a
                  useful tool for patients looking to increase their chances of
                  becoming pregnant following assisted reproduction therapy. A
                  Study on IVF treatment with acupuncture Researchers in Germany
                  conducted a study which included 160 patients undergoing IVF
                  with and without acupuncture. The patients, who were all
                  required to have good quality embryos, were evenly and
                  randomly divided into two groups similar in age and diagnosis.
                  When the patients were examined using ultrasound six weeks
                  after their IVF procedures, the differences in pregnancy rates
                  were notable. In the control group, 26 percent of the women,
                  or 21 out of 80 patients, became pregnant. Of the patients who
                  had received acupuncture treatments, 42 percent of the women,
                  34 out of 80, became pregnant.
                </Box>
                <Box color="#d4042a" fontWeight="semibold">
                  In the past ten years, our clinic has achieved an 80% success
                  rate in conceiving children, whether through natural
                  pregnancies or with IVF treatment.
                </Box>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="xl"
              >
                Acupuncture
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Stack gap="1rem">
                <Box>
                  Acupunture is an essential component of treatment in TCM, and
                  is ideal for treating conditions such as arthritis,
                  post-stroke rehabilitations, various neuropathies,
                  musculo-skeletal pains and migraines
                </Box>
                <Box>
                  It also involves the insertion of very fine needles into
                  points on the body. These points are located and join together
                  in 'channels' or 'meridians', along which Qi, blood and bodily
                  fluids flow. the points used in treatment are carefully chosen
                  by the TCM practitioner to disperse any blockages and to bring
                  the patient's Qi into balance.
                </Box>
                <Box>
                  This treatment is not painful. With insertion of very fine
                  needles the patient should experience numbness, distension or
                  heaviness around the points and along the course on which the
                  meridian runs. Many patients actually find the experience to
                  be quite relaxing.
                </Box>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="bold"
                fontSize="xl"
              >
                Herbal Medicine
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Stack gap="1rem">
                <Box>
                  Herbal Medicine in TCM describes formulae which are extracted
                  from special medicinal herbs. The herbs are usually decocted
                  into liquid form. We also stock pills or granules, called
                  'patent' herbal remedies, which are gentler to the palate.
                </Box>
                <Box>
                  Not only can your main complaint be controlled, but also your
                  whole sense of health and well - being improved, using herball
                  medicine, along with disussion about diet, lifestyle,
                  exercise, and stress management.
                </Box>
                <Box>
                  Chinese Herbal Medicine can also be used to minimize the side
                  effects caused by chemotherapy, radiation therapy, acupuncture
                  and effects of getting pregnant.
                </Box>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CSSTransition>
    </Stack>
  );
};

export default AboutUsPage;
