import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

export const colors = {
  content: {
    blue: "#B0D0D3",
    pink: "#FFCAD4",
    puce: "#C08497",
    melon: "#F7AF9D",
  },
  bg: {
    white: "#FFFFFF",
    overcast: "#F6F6F6",
    cloud: "#f7faf7",
    accent: "#CEDDE7",
  },
  text: {
    default: "#222D33",
    muted: "#3F525E",
    subtle: "#586973",
    links: "#9B1919",
  },
  outline: {
    light: "#C6C6C0",
  },
  error: {
    light: "#FEE1E8",
    normal: "#F41F52",
    dark: "#AA1639",
  },
  success: { light: "#D6F1D5", normal: "#66CB63", dark: "#31612F" },
  successToast: { 100: "#D6F1D5", 500: "#66CB63", 600: "#31612F" },
  warning: { light: "#FFF3C8", normal: "#FFCD1A", dark: "#874418" },
  info: { light: "#D8ECFF", normal: "#1ABAFF", dark: "#0D5D7F" },
};

export const fonts = {
  heading: `'DM Sans', sans-serif`,
  body: `'DM Sans', sans-serif`,
};

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      color: "text.default",
      bg: mode("white", "bg.overcast")(props),
    },
  }),
};
