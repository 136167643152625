import { Box } from "@chakra-ui/react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Outlet } from "react-router-dom";

const HomePage = () => {
  return (
    <Box pt="10rem">
      <Header />
      <Outlet />
      <Footer />
    </Box>
  );
};
export default HomePage;
